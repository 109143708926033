/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
// in gatsby-browser.js
import "./src/components/global.css"
require("prismjs/themes/prism-tomorrow.css")
